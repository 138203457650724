import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                            
                            Enterprise Software Development
                            
                            </h3>
                            <p>Developed based on an environment within an organization. It is also known as Custom Software development, and as a result, you usually get useful tools to develop the business.</p>

                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                             
                            Mobile App Development
                              
                            </h3>

                            <p>We deliver feature-rich, easy-to-use mobile solutions for all major platforms. Our mobile app development services are designed to deliver cutting-edge, bespoke solutions for your unique business requirements.</p>
                            
                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                           
                             UI / UX Designing Services
                           
                            </h3>

                            <p>Specialized in creating beautiful and smooth UI/UX designs that provide better user experience by incorporating effective collaboration for better results. We push brands to define their image with UI design services and make an enduring impact that drives and inspires users.</p>
                            
                            
                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                               
                             Cloud Hosting Services
                            
                            </h3>

                            <p>We generally recommend that clients procure a managed hosting solution. Managed hosting can be for a dedicated or virtualized server environment. By selecting managed hosting clients are able to rely on the hosting provider to manage the network, hardware, and software stack infrastructure 24/7/365 as part of the hosting costs.</p>
                           
                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                               
                            CMS Development
                            </h3>

                            <p>We utilize the latest technologies and industry trends to deliver Scalable and cutting-edge CMS-based web development services.</p>
                            
                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                
                              Backend Framework Development
                                
                            </h3>

                            <p>Backend web development frameworks are nothing but the libraries of server-side frameworks that aid to develop the structure of any site.</p>
                            
                            {/* <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne